import { RESERVATION_STATUS } from '@dbh/admindev-constants';
import createUseBoundEventCreator from '@dbh/create-use-bound-event-creator';

const REDUX_AND_SAGA_KEY="UserBookings";const USER_BOOKINGS_LOAD_INVOKED="@dbh/user-area-bookings-redux/USER_BOOKINGS_LOAD_INVOKED";const USER_BOOKINGS_LOAD_SUCCESS="@dbh/user-area-bookings-redux/USER_BOOKINGS_LOAD_SUCCESS";const USER_BOOKINGS_LOAD_FAILED="@dbh/user-area-bookings-redux/USER_BOOKINGS_LOAD_FAILED";const USER_BOOKINGS_LOADING="@dbh/user-area-bookings-redux/USER_BOOKINGS_LOADING";const NUMBER_OF_BOOKINGS_PER_PAGE=6;const YOUR_BOOKINGS_FILTER_TYPES=Object.freeze({UPCOMING:"UPCOMING",PAST:"PAST",CANCELLED:"C"});const YOUR_BOOKINGS_FILTER_TYPES_TO_RESERVATION_STATUS={[YOUR_BOOKINGS_FILTER_TYPES.UPCOMING]:RESERVATION_STATUS.R_CONFIRMED+","+RESERVATION_STATUS.S_HOTEL_CONFIRMATION_PENDING,[YOUR_BOOKINGS_FILTER_TYPES.PAST]:RESERVATION_STATUS.R_CONFIRMED+","+RESERVATION_STATUS.S_HOTEL_CONFIRMATION_PENDING+","+RESERVATION_STATUS.N_NO_SHOW+","+RESERVATION_STATUS.T_MAIL_PENDING,[YOUR_BOOKINGS_FILTER_TYPES.CANCELLED]:""+RESERVATION_STATUS.C_CANCELLED};

/**
 * Signed in user only.
 * @return {Object} .
 */const userBookingsLoadInvoked=()=>({type:USER_BOOKINGS_LOAD_INVOKED});const useUserBookingsLoadInvoked=createUseBoundEventCreator(userBookingsLoadInvoked);/**
 * Signed in user only. Invokeds the loading of the user bookings.
 * @param {Immutable.Map} obj .
 * @param {Immutable.Map} obj.apiResponse Response body received from the `API`.
 * @param {Immutable.Map} obj.requestAttributes .
 * @param {number} obj.pageNumber The current page in the `URL`.
 * @param {string} obj.country @example `IT`.
 * @param {string} obj.locale @example `en-GB`.
 * @return {Object} .
 */const userBookingsLoadSuccess=a=>{let{apiResponse:b,requestAttributes:c,country:d,locale:e,pageNumber:f}=a;return {type:USER_BOOKINGS_LOAD_SUCCESS,payload:{apiResponse:b,requestAttributes:c,country:d,locale:e,pageNumber:f}}};/**
 * Signed in user only. Signals that loading of the user bookings has failed.
 * @param {Immutable.Map} obj .
 * @param {Immutable.Map} obj.requestAttributes .
 * @param {string} obj.country @example `IT`.
 * @param {string} obj.locale @example `en-GB`.
 * @return {Object} .
 */const userBookingsLoadFailed=a=>{let{requestAttributes:b,country:c,locale:d}=a;return {type:USER_BOOKINGS_LOAD_FAILED,payload:{error:!0,requestAttributes:b,country:c,locale:d}}};/**
 * Signed in user only.
 * @param {Immutable.Map} obj .
 * @param {string} obj.country @example `IT`.
 * @param {string} obj.locale @example `en-GB`.
 * @return {Object} .
 */const userBookingsLoading=a=>{let{country:b,locale:c}=a;return {type:USER_BOOKINGS_LOADING,payload:{country:b,locale:c}}};

export { NUMBER_OF_BOOKINGS_PER_PAGE, REDUX_AND_SAGA_KEY, USER_BOOKINGS_LOADING, USER_BOOKINGS_LOAD_FAILED, USER_BOOKINGS_LOAD_INVOKED, USER_BOOKINGS_LOAD_SUCCESS, YOUR_BOOKINGS_FILTER_TYPES, YOUR_BOOKINGS_FILTER_TYPES_TO_RESERVATION_STATUS, useUserBookingsLoadInvoked, userBookingsLoadFailed, userBookingsLoadInvoked, userBookingsLoadSuccess, userBookingsLoading };
